// This file is generated by `gulp icons`, do not modify

$icons: (
  account: '\EA01',
  arrow--down: '\EA02',
  arrow--right: '\EA03',
  arrow: '\EA04',
  carat--l: '\EA05',
  carat--r: '\EA06',
  caret--down: '\EA07',
  caret--left: '\EA08',
  caret--right: '\EA09',
  caret--up: '\EA0A',
  cart: '\EA0B',
  checkbox--checked: '\EA0C',
  checkbox: '\EA0D',
  circle-arrow--left: '\EA0E',
  circle-arrow--right: '\EA0F',
  close: '\EA10',
  email: '\EA11',
  facebook: '\EA12',
  gift-icon: '\EA13',
  giftbox: '\EA14',
  hamburger: '\EA15',
  heart--selected: '\EA16',
  heart: '\EA17',
  instagram: '\EA18',
  journey--commitment: '\EA19',
  journey--fingerprint: '\EA1A',
  journey--formulate: '\EA1B',
  journey--source: '\EA1C',
  livechat: '\EA1D',
  location--filled: '\EA1E',
  location: '\EA1F',
  logo--solid: '\EA20',
  logo: '\EA21',
  minus: '\EA22',
  my-origins: '\EA23',
  pinterest: '\EA24',
  play: '\EA25',
  plus: '\EA26',
  product-attribute--acne: '\EA27',
  product-attribute--age-prevention: '\EA28',
  product-attribute--ageless-skin: '\EA29',
  product-attribute--basic: '\EA2A',
  product-attribute--dark-spot: '\EA2B',
  product-attribute--dry: '\EA2C',
  product-attribute--hydration: '\EA2D',
  product-attribute--lifting: '\EA2E',
  product-attribute--normal: '\EA2F',
  product-attribute--oily: '\EA30',
  product-attribute--redness: '\EA31',
  product-attribute--renewal: '\EA32',
  product-attribute--wrinkle: '\EA33',
  question-mark: '\EA34',
  radio--checked: '\EA35',
  radio: '\EA36',
  search-v2: '\EA37',
  search: '\EA38',
  stores: '\EA39',
  twitter: '\EA3A',
  wechat: '\EA3B',
  whatsapp: '\EA3C',
  youtube: '\EA3D'
);
