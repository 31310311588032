@import '../../../../scss/theme-bootstrap';

// new for 2018 Creative Refresh
// @todo remove this product-full scoping

.product-full-v2 {
  .product-full__image {
    .product-full__carousel {
      font-size: 0;
      white-space: nowrap;
      @include breakpoint($medium-up) {
        width: 100%;
        float: none;
        margin: 0;
      }
      &-slides {
        @include breakpoint($medium-up) {
          display: inline-block;
          width: 100%;
          margin-bottom: 16px;
          @include breakpoint($medium-up) {
            width: 72%;
            max-width: 490px;
          }
          @include breakpoint($large-up) {
            width: 78%;
          }
          @include breakpoint($largest-up) {
            width: 80%;
          }
        }
        white-space: normal;
        vertical-align: middle;
        .slick-list {
          @include breakpoint($medium-up) {
            aspect-ratio: 1;
            overflow: hidden;
          }
        }
      }
      &-slide {
        display: block;
        width: 100%;
        @include breakpoint($medium-up) {
          margin-bottom: 15px;
        }
        .yt-video-slider {
          &__reveal {
            display: none;
            position: absolute;
            top: 0;
            #{$ldirection}: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            transition: opacity $default-duration;
          }
        }
        &.slick-current {
          &.slick-active {
            .yt-video-slider {
              position: relative;
              &__container {
                position: relative;
              }
              &__close {
                position: absolute;
                #{$rdirection}: 0;
                text-align: $rdirection;
              }
              &__landing {
                position: relative;
                transition: opacity $default-duration;
                cursor: pointer;
              }
              &__play {
                position: absolute;
                top: 50%;
                #{$ldirection}: 50%;
                transform: translate(-50%, -50%);
                color: $color-white;
                font-size: 40px;
              }
              .icon--close {
                @include swap_direction(margin, 0 0 15px 10px);
                visibility: hidden;
                display: inline-block;
                cursor: pointer;
                font-size: 20px;
                color: $color-black;
              }
              &.active {
                .yt-video-slider {
                  &__reveal {
                    display: block;
                    opacity: 1;
                    height: 100%;
                    iframe {
                      height: 100%;
                      top: 0;
                      #{$ldirection}: 0;
                    }
                  }
                  &__landing {
                    z-index: 0;
                    opacity: 0;
                    top: 0;
                    #{$ldirection}: 0;
                  }
                  &__close,
                  .video-full-width__close {
                    background: $color-white;
                    z-index: 2;
                    .icon--close {
                      visibility: visible;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &-slide-img {
        display: block;
        margin: 0 auto;
      }
      &-thumbs {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        margin-bottom: 30px;
        position: relative;
        @include breakpoint($medium-up) {
          display: block;
          grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
          grid-auto-rows: auto;
          grid-gap: 16px;
          overflow: hidden;
          z-index: 1;
          max-height: 490px;
        }
        @include breakpoint($largest-up) {
          grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
          grid-auto-rows: auto;
        }
        .slick-list {
          @include breakpoint($largest-up) {
            max-height: 490px;
          }
        }
      }
      &-thumb {
        cursor: pointer;
        display: block;
        overflow: hidden;
        min-width: 75px;
        width: 75px;
        height: auto;
        margin-#{$rdirection}: 10px;
        @include breakpoint($medium-up) {
          width: 85%;
          max-height: 100px;
          max-width: 100px;
          aspect-ratio: 1;
          margin-#{$rdirection}: 10px;
          margin-bottom: 11px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        @include breakpoint($largest-up) {
          width: 100%;
          max-height: 117px;
          max-width: 117px;
        }
        &.active {
          border: 1px solid $color-black;
          box-shadow: inset 0 0 0 1px $color-cr18-black;
          opacity: 1;
        }
        &-image {
          display: block;
          height: auto;
          width: 75px;
          min-width: 75px;
          @include breakpoint($medium-up) {
            width: 100%;
          }
        }
        &-link {
          span.yt-video__play {
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
            transform: translate(100%, 85%);
            text-decoration: none;
            position: absolute;
            color: $color-white;
            font-size: 27px;
            @include breakpoint($medium-up) {
              font-size: 40px;
            }
          }
        }
        img {
          @include breakpoint($medium-up) {
            width: 112px;
          }
        }
      }
    }
    .slick-dots li.slick-active button::before {
      background: $color-dark-gray;
      color: $color-dark-gray;
    }
    .carousel-dots {
      margin-top: -30px;
      margin-bottom: 10px;
    }
    .slick-arrow {
      &:before {
        font-size: 12px;
      }
    }
    .slick-prev,
    .slick-next {
      color: $color-black;
      background-color: $color-white;
      width: 32px;
      height: 16px;
      margin: 0;
      #{$ldirection}: 35px;
      @include breakpoint($largest-up) {
        #{$ldirection}: 42px;
      }
      &:before {
        left: 11px;
        right: auto;
      }
    }
    .slick-prev {
      @include icon('caret--up', before);
      top: 0;
      &:before {
        top: 3px;
      }
    }
    .slick-next {
      @include icon('caret--down', before);
      top: auto;
      bottom: 0;
      right: 0;
      &:before {
        top: auto;
        bottom: 0;
        height: auto;
      }
    }
    .product-full__carousel-thumb:not(.slick-active) {
      @include breakpoint($medium-up) {
        margin-#{$rdirection}: 11px;
        margin-bottom: 8px;
      }
    }
    .product-full__carousel-thumb.slick-active {
      &[style] {
        @include breakpoint($largest-up) {
          width: 114px!important;
        }
      }
    }
    .product-full__carousel-thumbs.slick-initialized {
      .slick-list[style] {
        @include breakpoint($medium-up) {
          height: 433px!important;
        }
        @include breakpoint($large-up) {
          height: 444px!important;
        }
        @include breakpoint($largest-up) {
          height: 500px!important;
        }
      }
    }
  }
  .slick-dots {
    li {
      margin: 0 8px;
    }
  }
}
